@include breakpoint(medium down) {
	.small-up-1,
	.medium-up-2,
	.large-up-3 {
		.card {
			margin-bottom: rem-calc(30);
		}
	}
}



.grid-container[data-block=icon-card-list]{
	@include breakpoint(large) {
		.card__content {
			h3 {
				font-size: rem-calc(18);
			}
		}
	}
}

.card{
	overflow: visible;
	flex-grow: 0;
	@include breakpoint(medium) {
		margin-bottom: 0;
	}
	&__content {
		padding: rem-calc(15);
		@include breakpoint(large){
			padding: rem-calc(28 28 20);
		}
		>*{
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	&.has-image{
		@include cover-bg;
		overflow: hidden;
		min-height: rem-calc(450);
		@include breakpoint(small only){
			min-height: rem-calc(400);
		}
	}
	&__location{
		&.has-image{
			//min-height: rem-calc(160) !important;
		}
		> div {
			padding-right: 30px;
		}
		i{
			position: absolute;
			right: rem-calc(-6px);
			bottom:rem-calc(10px);
			&::before{
				font-size: rem-calc(20px);
			}
		}
		&.has-overlay{
			&::after{
				height: rem-calc(100);
			}
		}
	}
	&.has-shadow{
		box-shadow: rem-calc(0 1px 2px) rgba(#000000,.16);
	}
	&.has-border{
		border:1px solid $light-gray;
	}
	&.align-bottom{
		justify-content: flex-end;
		flex-direction: column;
	}
	&.content-white{
		* {
			color: $white;
		}
	}
	.home-cases{
		margin-top:rem-calc(12);
		display: flex;
		flex-direction: column;
		flex-grow: inherit;
		justify-content: space-between;
	}
	&.has-icon{
		border:1px solid #CECECE;
		padding-bottom: rem-calc(20);
		img{
			display: inline-block;
			max-width: fit-content;
		}
		h3{
			margin-top: rem-calc(20);
			margin-bottom: rem-calc(15);
			&.mt-0{
				margin-top:0;
			}
			&.mb-0{
				margin-bottom:0;
			}
		}
		>p{
			margin-top: rem-calc(5);
		}
		&[href]{
			@include transition(all);
			p{
				color: #ADADAD;
			}
			&:hover{
				box-shadow: 0 3px 6px rgba(#000,.1);
			}
		}
	}
	&__multiple{
		a{
			&.has-image {
				min-height: auto;
			}
			@include breakpoint(medium){
				height: calc(50% - 30px);
			}
			@include breakpoint(large){
				height: calc(50% - 10px);
			}
		}
	}
	&__icon-container{
		height: rem-calc(28);
	}
	&.has-contact{
		padding-bottom: rem-calc(20);
		h4{
			margin-bottom: rem-calc(15);
			color:$body-font-color;
		}
		.card__contact-photo{
			@include cover-bg;
			@include aspect-ratio(3, 4);
			width: rem-calc(120px);
			margin-bottom: rem-calc(15);
		}
		p{
			font-weight: 500;
			a{
				font-size: rem-calc(16);
				color:$body-font-color;
				font-weight: 500;
				&:hover{
					color:$secondary-color;
				}
			}
		}
	}
	&.has-overlay{
		position: relative;
		&::after{
			@include transition(all);
			position: absolute;
			bottom:0;
			left: 0;
			width: 100%;
			z-index: 1;
			content: '';
			height: rem-calc(200);
		}
		&:hover{
			&::after{
				height: 75%;
				bottom:rem-calc(-1);
			}
		}
		>div{
			z-index: 2;
			position: relative;
			width: 100%;
		}
		&--blob{
			&::before{
				position: absolute;
				bottom:0;
				left: 0;
				width:rem-calc(234);
				height: rem-calc(211);
				z-index: 2;
				content: '';
			}
		}
		@each $colorName, $colorValue in $colors {
			&--#{$colorName}{
				@extend .has-overlay;
				&::after {
					background: -moz-linear-gradient(top, rgba($white, 0) 0%, rgba($colorValue, .9) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba($white, 0) 0%, rgba($colorValue, .9) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($colorValue, .9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='$colorValue', GradientType=0); /* IE6-9 */
				}
				&.has-overlay--blob {
					&::before {
						@if $colorName == 'primary' {
							background-image: url("../images/svg/elements/overlay-blob--primary.svg");
						}
						@if $colorName == 'secondary' {
							background-image: url("../images/svg/elements/overlay-blob--secondary.svg");
						}
					}
				}
			}
		}
	}
	h3{
		&.border-title--left{
			margin-bottom: rem-calc(14);
		}
	}
	p{
		margin-bottom: rem-calc(8);
		&:last-child{
			margin-bottom: 0;
		}
	}
	&-event{
		&__date{
			font-size: rem-calc(14);
			font-weight: 700;
			text-transform: lowercase;
			color: $medium-gray;
			line-height: 1;
		}
		h4{
			margin-bottom: rem-calc(8) !important;
			a{
				//font-weight: normal;
				//font-size: rem-calc(18);
				line-height: 1.5;
			}
		}
		&__excerpt{
			margin-bottom: rem-calc(15);
		}
		.card__content{
			padding-bottom: rem-calc(18);
			+.card__content{
				border-top:1px solid $light-gray;
			}
		}
	}
	.accordion{
		&-item{
			border-top:1px solid $light-gray;
		}
		&-title{
			font-size: rem-calc(16);
			color:$body-font-color;
			font-weight: 500;
			&::before{
				@include transition(all);
				font-family: Canisius;
				content: '\e904';
			}
			&[aria-expanded="true"]{
				&::before{
					@include transform(rotate(180deg));
				}
			}
		}
		.card{
			padding-top:0;
		}
	}
	&-list {
		.card {
			background: #e8e8ee;
			border-radius: 0;
			box-shadow: 0 0 10px rgba(0,0,0,0.75);
			h3 {
				margin-bottom: 1.2rem;
			}
		}
	}
}
