.skew {
	position: absolute;
	left: 0;
	right: 0;

	height: 40px;
	@include breakpoint(medium) {
		height: 80px;
	}
	@include breakpoint(large) {
		height: 150px;
	}

	svg {
		height: 100%;
		width: 100%;
	}

	&-top-left {
		top: 0;
	}
	&-top-right {
		top: 0;
		transform: scaleX(-1);
	}
	&-bottom-left {
		bottom: 0;
		transform: scaleY(-1);
	}
	&-bottom-right {
		bottom: 0;
		transform: scaleX(-1) scaleY(-1);
	}
}
.has-skew {
	position: relative;
	//background: red;
}