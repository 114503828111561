.card-news-list{
	margin:rem-calc(10 0 0 -15);
	width: calc(100% + 30px);
	@include breakpoint(large){
		margin:rem-calc(10 0 0 -28);
		width: calc(100% + 56px);
	}
	list-style: none;
	padding:0;
	&__item{
		a{
			@include transition(all);
			display: block;
			padding:rem-calc(15px 15px);
			border-left: rem-calc(3) solid $white;
			@include breakpoint(large) {
				padding: rem-calc(15px 25px);
			}
			&:hover {
				border-color:$secondary-color;
				background: $light-gray;
				color: $body-font-color;
			}
		}
	}
	&__date{
		font-size:rem-calc(14);
		font-weight: 400;
	}
	&__title{
		font-weight: 500;
	}
}
