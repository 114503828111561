footer{
	position: relative;

}

.footer{

	margin-top: 40px;
	@include breakpoint(medium) {
		margin-top: 80px;
	}
	@include breakpoint(large) {
		margin-top: 150px;
	}

	$p: &;
	&__overlay{
		background: $white;
		pointer-events: none;
		//position: absolute;
		width: 100%;
		justify-content: end;
		//transform: translateY(-98%);
		flex-direction: column;
		display: flex;
		svg{
			.st0{
				fill: $secondary-color;
			}
		}
	}
	&__content{
		background: $secondary-color;
		@extend .content--white;
		padding: rem-calc(50 0 80);
		@include breakpoint(medium) {
			padding: rem-calc(50 0 120);
		}
		h1,.h1{
			transform: translateY(-100%);
			@include breakpoint(medium) {
				margin-bottom: 40px;
			}
			@include breakpoint(large) {
				margin-bottom: 50px;
			}
		}
		.large-arrow-link{
			color:rgba($white,.75);
			font-weight: 300;
		}
		p{
			margin-bottom: rem-calc(0);
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	&__decoration {
		z-index: 1;
		@include aspect-ratio(50,22);
		padding-left: 30px;
		@include breakpoint(small only) {
			max-width: 90%;
		}
		&:before {
			z-index: -1;
			left: 0;
			position: absolute;
			background: url(../images/svg/elements/footer-decoration-mobile.svg);
			background-size: cover;
			background-repeat: no-repeat;
			top: 50%;
			transform: translateY(-50%);
		}
		@include breakpoint(medium) {
			padding-left: 20%;
			left: -10%;
			@include aspect-ratio(13,5);
			&:before {
				background-image: url(../images/svg/elements/footer-decoration.svg);
			}
		}


	}
	@include breakpoint(small only) {
		&-menu {
			li{
				margin:0 !important;
				width: 100%;
				text-align: center;
			}
		}
	}
	&__social{
		background: $primary-color;
		padding:rem-calc(50 0);
		@include breakpoint(small only) {
			text-align: center;
			ul {
				margin-bottom: rem-calc(20);
			}
		}
	}
	&__copy{
		@extend .content--white;
		background: darken($primary-color, 10%);
		padding:rem-calc(30 0);
		p{
			margin:0;
			opacity: 1;
			color: $white;
		}
		@include breakpoint(small only) {
			text-align: center;
		}
	}
	&--primary{
		background: $primary-color;
		svg{
			.st0{
				fill:$primary-color;
			}
		}
		#{$p}__social{
			background: darken($primary-color, 15%);
		}
		#{$p}__copy{
			background: darken($primary-color, 20%);
			p{
				color:$white;
			}
		}
	}
	.skew-bottom-left {
		top: 0;
		bottom: auto;
		transform: translateY(-100%) scaleY(-1);
	}
}
