.top-menu{
	@include border-radius(0 0 $global-radius $global-radius);
	background-color:$light-gray;
	text-align: right;
	list-style-type: none;
	display: inline-block;
	padding:rem-calc(5px 7px);
	margin: 0;
	position: relative;
	&__item{
		display: inline-block;
		a,span{
			//font-family: $trebuchet;
			color: $medium-gray;
			padding: rem-calc(9px 13px);
			display: inline-block;
			font-weight: 700;
			font-size: rem-calc(14);
			@include border-radius($global-radius);
			&.primary,
			&.white{
				font-weight: 700;
			}
			&.white{
				background: $white;
				color:$body-font-color;
			}
			&.primary{
				background: $primary-color;
				color:$white;
				&:hover {
					color: $white;
				}
			}

		}
		> a {
			&:hover {
				color: $primary-color;
			}
		}
		+li{
			margin-left: rem-calc(3);
		}
		ul{
			margin:0;
			padding:rem-calc(20 20 15);
			list-style: none;
			li{
				width: 100%;
				text-align: left;
				margin-bottom:1px;
			}
			a{
				@include transition(color);
				white-space: nowrap;
				display: block;
				font-size: rem-calc(14);
				font-weight: 700;
				&:hover{
					color:$secondary-color;
				}
			}
		}
		span{
			cursor: pointer;
			@include transition(all);
			&:hover{
				box-shadow: 0 5px 5px rgba($black, .05);
			}
			&[aria-expanded="true"]{
				border-radius: $global-radius $global-radius 0 0;
				box-shadow: 0 5px 5px rgba($black, .16);
			}
		}
		.dropdown-pane{
			min-width:rem-calc(240);
			border-radius: $global-radius 0 $global-radius $global-radius;
			box-shadow: 0 5px 5px rgba($black, .16);
		}
		&:first-child {
			position: absolute;
			right: 100%;
			white-space: nowrap;
			margin-right: 38px;
			a {
				color: $white;
			}
			&:before {
				content: '';
				position: absolute;
				width: 136px;
				height: 52px;
				background-image: url(../images/svg/elements/topmenu-bg.svg);
				background-repeat: no-repeat;
				z-index: -1;
				top: -5px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}