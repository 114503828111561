//Sticky footer
.page__container{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
.page__content{
	flex: 1 0 auto;
}

footer {
	flex-shrink: 0;
}

@include breakpoint(large){
	hr + .two-column__container{
		margin-top:rem-calc(-50px);
	}
	.two-column__container + hr{
		margin-top:rem-calc(-50px);
	}
}

@include breakpoint(medium only){
	hr + .two-column__container{
		padding-top:rem-calc(100px);
	}
	.two-column__container + hr{
		margin-top:rem-calc(100px);
	}
}

@include breakpoint(small only){
	hr + .two-column__container{
		padding-top:rem-calc(50px);
	}
	.two-column__container + hr{
		margin-top:rem-calc(50px);
	}
}
.block__title {
	@include xy-grid-container()
}

.homepage {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ styles */
    .homepage {
        -ms-box-orient: none;
        box-orient: none;
        display: block;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        min-height: inherit;
    }
}

.homepage .page__container {
	min-height: initial;
}
.homepage .off-canvas-wrapper {
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	-ms-box-flex: 1;
	box-flex: 1;
	-webkit-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
}
.homepage .grid-container[data-block=image-card-list] {
	max-width: rem-calc(1600);
}
.homepage .grid-container[data-block=image-card-list] .card.has-image {
	min-height: rem-calc(200);
	@include breakpoint(medium) {
		min-height: rem-calc(300);
		height: 40vh;
	}
}
