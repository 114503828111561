.home-usp{
	@include breakpoint(medium) {
		margin-top: rem-calc(-300px);
	}
	z-index: 5;
	position: relative;
	.card.has-image{
		min-height: rem-calc(350px);
	}
	.is-disabled{
		cursor: not-allowed;
		a{
			pointer-events: none;
			background:$gray;
			opacity: 0.5;
		}
	}
}
