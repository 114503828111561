.event-list{
	&__image{
		@include aspect-ratio(16,9);
		@include cover-bg;
		border-radius: $global-radius $global-radius 0 0;
	}
	&__day{
		width: 64px;
		height: 64px;
		color:$white;
		display: inline-block;
		font-family: $raleway;
		font-size: rem-calc(24);
		background: $secondary-color;
		border-radius: $global-radius;
		padding: 15px 5px;
		text-align: center;
		&-container{
			position: absolute;
			top: 15px;
			left: 15px;
		}
	}
	&__item{
		@include transition(all);
		margin-bottom: rem-calc(30);
		.card__content{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex-grow: 1;
		}
		&:hover{
			box-shadow: 0 3px 6px rgba(#000,.1);
		}
	}
	&__date{
		font-size: rem-calc(14);
		font-weight: 700;
		text-transform: lowercase;
		color: $medium-gray;
	}
	&__title{
		margin-bottom: rem-calc(8) !important;
		a{
			font-weight: normal;
			font-size: rem-calc(18);
			line-height: 1.5;
		}
	}
	&__excerpt{
		margin-bottom: rem-calc(15) !important;
		color:$body-font-color;
	}
	.button--secondary{
		font-size: rem-calc(14);
		padding:rem-calc(18 22);
	}
}
