h1,h2,h3,h4,h5,h6{
	line-height: 1.3;
	@include breakpoint(medium) {
		line-height: 1.2;
	}
	&.subtitle{
		font-family: $body-font-family;
		font-weight: 300;
	}
}

h1,.h1{
	letter-spacing: 1px;
	font-weight: 900;
}

h3{
	&.border-title--left{
		position: relative;
		&:before{
			background: #F0F2F5;
			display: block;
			top: 0;
			height: 100%;
			content: '';
			width: rem-calc(3);
			position: absolute;
			left: rem-calc(-15);
			@include breakpoint(large) {
				width: rem-calc(6);
				left: rem-calc(-28);
			}
		}
	}
}
