.off-canvas{
	&.position-bottom{
		-webkit-box-shadow: 0px -34px 175px 1px rgba(0,0,0,0.4);
		-moz-box-shadow: 0px -34px 175px 1px rgba(0,0,0,0.4);
		box-shadow: 0px -34px 175px 1px rgba(0,0,0,0.4);
	}
}

.download__canvas{
	padding:rem-calc(100 0);
	width: 100%;
	background: $white;
}
.one-column-download {
	position: relative;
	z-index: 3;
	.grid-container {
		padding-top: rem-calc(24);
	}
}