.landing-container{
	.banner{
		height: rem-calc(300);
		@include breakpoint(medium) {
			height: rem-calc(400);
		}

		@include breakpoint(large) {
			height: rem-calc(600);
		}
		@include breakpoint(xlarge) {
			height: rem-calc(800);
		}
		margin-bottom: 0;
		position: relative;
		z-index: 1;
		.grid-container{
			position: relative;
			height: auto;
			top: 20%;
			transform: translateY(-50%);
		}
		h1 {
			color:$white;
			font-family: $raleway;
			font-weight: 900;
			font-size: rem-calc(30);
			line-height: 1.1;
			@include breakpoint(medium) {
				font-size: rem-calc(72);
			}
		}
	}
}