.calendar-detail{
	$dayWidth: rem-calc(35);

	max-width: rem-calc(350);
	>.grid-x {
		margin-bottom: rem-calc(40);
	}
	.grid-x{
		margin-bottom: rem-calc(10);
		&.header{
			margin-bottom: rem-calc(20);
		}
	}
	.header{
		text-align: center;
		color: $body-font-color;
		font-size: rem-calc(15);
		font-family: $raleway;
		&_day{
			display: inline-block;
			width: $dayWidth;
		}
	}
	.small_cal{
		width: 100%;
	}
	#small_cal_year{
		color: $secondary-color;
		font-size: rem-calc(24);
		font-family: $raleway;
	}
	.cal{
		&_blank{
			span{
				width: $dayWidth;
				height: $dayWidth;
				display: inline-block;
			}
		}
		&_date{
			@include border-radius($global-radius);
			padding:rem-calc(11);
			display:inline-block;
			width: $dayWidth;
			height: $dayWidth;
			line-height: 1.2;
			text-align: center;
			margin:0 auto;
			font-family: $raleway;
			font-size: rem-calc(11);
			background:#FAFBFD;
			color:#A7A7A7;
			&.is-active{
				background:$primary-color !important;
				color:$white !important;
			}
		}
		&_day{
			text-align: center;
			&.has-event{
				.cal_date{
					background:$secondary-color;
				}
				.cal_date{
					color: $white;
				}
			}
		}
	}
}