.two-column {
	&__container {
		@include cover-bg;
		@extend .content--white;
		padding: 200px 0;
		position: relative;
		.content {
			position: relative;

		}
		.text {
			position: relative;
			z-index: 1;
		}
	}

	&__decoration {
		position: absolute;
		left: 50%;
		top: 40%;
		transform: translate(-50%, -50%);
		width: 120%;
		height: 150%;
		z-index: 0;
		background: url(../images/svg/elements/textblock-decoration.svg);
		@include cover-bg;
	}

}