.banner{
	position: relative;
	padding:rem-calc(40px 0 60px);
	@include cover-bg;
	@include breakpoint(small only) {
		margin-bottom: rem-calc(24);
	}

	@include breakpoint(medium) {
		padding: rem-calc(75px 0 110px);
	}
	@include breakpoint(large) {
		padding: rem-calc(100px 0 150px);
	}
	&__overlay{
		//transform: translateY(-2px);
	}
	.skew {
		bottom: -1px;
		z-index: 1;
	}
	&.has-overlay {
		overflow: visible;
		.grid-container{
			z-index: 5;
			position: relative;
		}
		&::before{
			@include transition(all);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
			content: '';
			height: 100%;
			opacity: .75;
		}
		@each $colorName, $colorValue in $colors {
			&--#{$colorName} {
				@extend .has-overlay;
				&::before {
					background: linear-gradient(to right, rgba($colorValue, .9) 0%, rgba($white, 0) 50%);
				}
			}
		}
	}
	h1 {
		line-height: 1.1;
	}
	h1,p{
		color:$white;
	}
	p{

		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: rem-calc(5);
		font-size: rem-calc(12);
		@include breakpoint(medium) {
			font-size: rem-calc(14);
			margin-bottom: rem-calc(15);
		}
	}
	.grid-container,
	.grid-x{
		height: 100%;
	}
	>.grid-container{
		>.grid-x{
			>.cell{
				@include breakpoint(1490px) {
					margin-left: $pullLeft;
				}
			}
		}
	}
}
