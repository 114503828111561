.video-banner{
	@include cover-bg;
	position: relative;
	padding: rem-calc(60 0 30);
	@include breakpoint(large) {
		padding: rem-calc(100 0 30);
	}
	&__content{
		@include vertical-center;
		z-index: 5;
		width: 100%;
	}
	&__inner-content{
		position: relative;
		padding-left: 20%;
		.decoration {
			@include aspect-ratio(118, 50);
			position: absolute;
			width: 140%;
			display: block;
			top: 20%;
			@include breakpoint(large) {
				top: 60%;
			}
			left: 50%;
			transform: translate(-50%, -50%);
			&::before {
				content: '';
				position: absolute;

				left: rem-calc(-50);
				background-image: url('../images/svg/elements/video-decoration.svg');
				background-repeat: no-repeat;
				@include translateY(-10%);

				@include breakpoint(medium) {
					@include translateY(-26%);


				}
				@include breakpoint(large) {
					@include translateY(-40%);


				}
			}
		}
		@include breakpoint(small only){
			.large-arrow {
				width: 40px;
				height: 30px;
			}
		}
		div.text{
			position: relative;
			z-index: 1;
			transform: rotate(-3.1deg);
			h1,h2 {
				display: inline-block;
				line-height: 1;
			}
		}
	}
	p{
		font-size: rem-calc(30);
		font-weight: 300;
		margin-bottom: 0;
	}
	iframe {
		@include transition(opacity);
		opacity: 0;
		pointer-events: none;
	}
	&.has-started{
		iframe {
			opacity: 1;
			pointer-events: inherit;
		}
	}
}
.page__content {
	.grid-container {
		.video-banner {
			//background: red;

			.video-banner__inner-content::before {
				width: 93%;
				height: 0;
				padding-bottom: 79.66%;
				-webkit-transform: none;
				transform: translateY(-50%) scale(1);
				left: -30px;
				top: 40%;
				@include breakpoint(medium) {
					width: 100%;
					height: 0;
					padding-bottom: 79.66%;
					-webkit-transform: none;
					transform: translateY(-50%) scale(1.16);
					left: -30px;
					top: 30%;
				}

			}
		}
	}
}