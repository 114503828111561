@charset 'utf-8';
@import url("https://use.typekit.net/orb5uii.css");
@import 'init/_init';

@import 'settings';

@import 'init/loops';

@import '../../node_modules/foundation-sites/scss/foundation.scss';
@import '../../node_modules/motion-ui/src/motion-ui.scss';

$icomoon-font-path: "../../dist/fonts/Canisius/fonts" !default;

$slick-font-family: "" !default;
$slick-loader-path: "../images/" !default;
$slick-prev-character: "\02190" !default;
$slick-next-character: "\02192" !default;
$slick-arrow-color: black !default;

@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
.reveal{
    .close-button{
        padding: 0 !important;
    }
}
@include foundation-flex-classes;
@include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-global-styles;
@include foundation-accordion;
@include foundation-button;
@include foundation-breadcrumbs;
@include foundation-card;
@include foundation-close-button;
@include foundation-callout;
@include foundation-forms;
@include foundation-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-reveal;
@include foundation-title-bar;
@include foundation-top-bar;
@include foundation-responsive-embed;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-off-canvas;
@include foundation-tabs;
@include foundation-sticky;
@include foundation-typography

@include motion-ui-transitions;
@include motion-ui-animations;

@import 'base/_base';
@import 'modules/_modules';
@import 'layout/_layout';

.page {
	&__container {
		padding-top: $responsiveMenuHeight;
		@include breakpoint(large) {
			padding-top: $menuHeight;
		}
	}
	&__content{
		z-index: 5;
	}
}
